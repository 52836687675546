@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./custom.scss);

:root,
html,
* {
	font-family: "Univia Pro";
	letter-spacing: normal;
}
