$rose: #e4004b;
$rose-light: #ef6693;
$rose-dark: #ca0043;

:root,
html,
* {
	font-family: "Univia Pro";
	letter-spacing: normal;
}

body {
	position: relative;
	background-color: #cbcbcc;
}

div.fill {
	position: relative;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	img {
		min-height: 100%;
		min-width: 100%;
		object-fit: cover;
	}
}

.map-container {
	height: 100%;
	width: 100%;
}

/* LoadingBar.css */

.loading-bar-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 10px;
	width: 100%;
	overflow: hidden;
	border-radius: 9999px;
}

.loading-bar {
	width: 100%;
	max-width: 400px;
	height: 20px;
	background-color: #a3a3a3;
	position: relative;
}

.loading-progress {
	width: 0;
	height: 20px;
	background-color: $rose-dark; /* Color of the loading progress bar */
	position: absolute;
	animation: loading-progress-animation 5s linear;
}

@keyframes loading-progress-animation {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

p {
	margin-top: 20px;
	font-weight: bold;
}
